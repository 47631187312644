import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from "classnames";

export default class Staff extends React.Component {
  render() {
    let { name, tribe, title, image } = this.props.content;
    return (
      <Container className={classNames(this.props.className)}>
        <Row className="py-3 justify-content-center align-items-center">
          <Col xs={3} md={2}>
            { image && <GatsbyImage image={getImage(image)} alt={name} />}
          </Col>
          <Col xs={9} md={8} lg={6} xl={5}>
          {name} {tribe && <span><em>({tribe})</em></span>} {title && <span>&ndash; {title}</span>}
          </Col>
        </Row>
      </Container>
    )
  }
}

Staff.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string
}

export const staffFragment = graphql`
  fragment StaffMember on ContentfulStaffMember {
    name
    tribe
    title
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        width: 350
      )
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`