import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby";
import Section from "../components/Section"
import Feature from "../components/Feature";
import Staff from "../components/Staff";
import Headline from "../components/Headline";

export default class About extends React.Component {
  render() {
    let about = this.props.data.about;
    let staff = this.props.data.staff.edges.map(({ node }) => node);
    return ( 
      <Layout>
        <Seo title="About" />
        {about && <Section className="white" useContainer={false}>
          <Feature content={about} centerVertically={true} />
        </Section> }
        {staff && <Section className="secondary" centerHorizontally={true}>
          <Headline center={true}>OEW Staff</Headline>
          {staff.map((staffMember) => {
              return (
                <Staff key={"staff-" + staffMember.name} content={staffMember} />
              );
            })}
        </Section> }
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    about: contentfulVideoResource(slug: { eq: "about" }) {
      ...VideoResource
    }
    staff: allContentfulStaffMember(sort: {fields: [displayOrder], order: [ASC]}) {
      edges {
        node {
          ...StaffMember
        }
      }  
    }
  }
`
